import { RouteRecordRaw } from "vue-router";

export const Coupon: Array<RouteRecordRaw> = [
  {
    path: "/coupons",
    name: "coupon-index",
    component: () => import("@/views/coupon/CouponView.vue"),
    meta: { resources: ["Coupon"] },
    children: [
      {
        path: "/coupons/create",
        name: "coupon-create",
        component: () => import("@/views/coupon/CouponFormPage.vue"),
        meta: { resources: ["Coupon"] },
      },
      {
        path: "/coupons",
        name: "coupon-index",
        component: () => import("@/views/coupon/CouponIndex.vue"),
        meta: { resources: ["Coupon"] },
      },
      {
        path: "/coupons/edit/:id",
        name: "coupon-edit",
        component: () => import("@/views/coupon/CouponFormPage.vue"),
        meta: { resources: ["Coupon"] },
      },

    ],
  },
  {
    path: "/coupons/import",
    name: "coupon-import",
    component: () => import("@/views/coupon/CouponsTrackingImport.vue"),
    meta: { resources: ["Coupon"] },
  },

  {
    path: "/coupons-publishers",
    name: "coupon-publisher",
    component: () => import("@/views/coupon/publishers/CouponPublishersView.vue"),
    meta: { resources: ["Coupon"] },
    children: [
      {
        path: "/coupons-publishers",
        name: "coupon-publishers-index",
        component: () => import("@/views/coupon/publishers/CouponPublishersEshop.vue"),
        meta: { resources: ["Coupon"] },
      },
      {
        path: "/coupons-publishers/tracking",
        name: "coupon-publishers-tracking",
        component: () => import("@/views/coupon/publishers/CouponPublishersTracking.vue"),
        meta: { resources: ["CouponTracking"] },
      },
      {
        path: "/coupons-publishers/extra",
        name: "coupon-publishers-extra",
        component: () => import("@/views/coupon/publishers/CouponPublishersExtra.vue"),
        meta: { resources: ["CouponExtra"] },
      },
      {
        path: "/coupons-publishers/action",
        name: "coupon-publishers-action",
        component: () => import("@/views/coupon/publishers/CouponPublishersAction.vue"),
        meta: { resources: ["CouponAction"] },
      },
      {
        path: "/coupons-publishers/eshop",
        name: "coupon-publishers-eshop",
        component: () => import("@/views/coupon/publishers/CouponPublishersEshop.vue"),
        meta: { resources: ["Coupon"] },
      },
      {
        path: "/coupons-publishers/affiliate",
        name: "coupon-publishers-affiliate",
        component: () => import("@/views/coupon/publishers/CouponPublishersAffiliate.vue"),
        meta: { resources: ["Coupon"] },
      },

      {
        path: "/coupons-publishers/detail/:id",
        name: "coupon-publishers-detail",
        component: () => import("@/views/coupon/CouponFormPage.vue"),
        meta: { resources: ["Coupon"], readonly: true },
      },

    ],
  }
];
export default Coupon;
