import { RouteRecordRaw } from "vue-router";

export const Report: Array<RouteRecordRaw> = [
  {
    path: "/reports",
    name: "reports",
    component: () => import("@/views/report/ReportsManagement.vue"),
    children: [
      {
        path: "top-click-referrers",
        name: "top-click-referrers",
        component: () => import("@/views/report/admin/TopClickReferrers.vue"),
      },
      {
        path: "overview",
        name: "reports-overview",
        component: () => import("@/views/report/admin/Overview.vue"),
      },
      {
        path: "publishers-old",
        name: "reports-publishers-old",
        component: () => import("@/views/report/admin/PublishersOld.vue"),
      },
      {
        path: "publishers",
        name: "reports-publishers",
        component: () => import("@/views/report/admin/Publishers.vue"),
      },
      {
        path: "publisher-type",
        name: "reports-publisher-type",
        component: () => import("@/views/report/admin/PublishersType.vue"),
      },
      {
        path: "comparison-kpi",
        name: "reports-comparison-kpi",
        component: () => import("@/views/report/admin/ComparisonKPI.vue"),
      },
      {
        path: "comparison-kpi-monthly",
        name: "reports-comparison-kpi-monthly",
        component: () => import("@/views/report/admin/ComparisonKPIMonthly.vue"),
      },
      {
        path: "publisher-overview",
        name: "reports-publisher-overview",
        component: () => import("@/views/report/publisher/Overview.vue"),
      },
      {
        path: "publisher-campaigns-stats",
        name: "reports-publisher-campaigns-stats",
        component: () => import("@/views/report/publisher/CampaignsStats.vue"),
      },
      {
        path: "publisher-time-review",
        name: "reports-publisher-time-review",
        component: () => import("@/views/report/publisher/TimeReview.vue"),
      },
    ],
  },
];
export default Report;
