import { RouteRecordRaw } from "vue-router";

export const Account: Array<RouteRecordRaw> = [
  {
    path: "/accounts",
    name: "account",
    component: () => import("@/views/account/account-management/AccountManagement.vue"),
    meta: { resources: ["Account"] },
    children: [
      {
        path: "",
        name: "account-management-index",
        component: () => import("@/views/account/account-management/AccountManagementIndex.vue"),
        meta: { resources: ["Account"] },
      },
      {
        path: "/accounts/edit/:id",
        name: "account-management-edit",
        component: () => import("@/views/account/account-management/AccountManagementEdit.vue"),
        meta: { resources: ["Account"] },
      },
      {
        path: "/accounts/detail/:id/merchants",
        name: "account-management-show-merchants",
        component: () => import("@/views/account/account-management/AccountManagementShow-merchants.vue"),
        meta: { resources: ["Account"] },
      },
    ],
  },
];

export default Account;
