import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

interface ColumnType {
  name: string;
  field: string;
  hidden: boolean;
}

interface FilterType {
  field: string;
  value: any[];
}

interface ReportsState {
  initialColumns: ColumnType[];
  columns: ColumnType[];
  selectedFilter: FilterType | null;
}

@Module({ namespaced: true })
class ReportsStore extends VuexModule implements ReportsState {
  public initialColumns: ColumnType[] = [];
  public columns: ColumnType[] = [];
  public selectedFilter: FilterType | null = null;

  @Mutation
  setInitialColumns(columns: ColumnType[]) {
    this.initialColumns = columns;
  }

  @Mutation
  setColumns(columns: ColumnType[]) {
    this.columns = columns;
  }

  @Mutation
  resetColumns() {
    this.columns = [...this.initialColumns];
  }
  @Mutation
  updateInitialColumns(updatedColumns: ColumnType[]) {
    // Create a new map for easy access and updates
    const columnMap = new Map(this.initialColumns.map((col) => [col.field, col]));

    // Update the map with the new columns
    updatedColumns.forEach((col) => {
      if (columnMap.has(col.field)) {
        // Update existing column properties
        const existingCol: any = columnMap.get(col.field);
        for (const key in col) {
          existingCol[key] = col[key];
        }
      } else {
        // Add new column if it doesn't exist
        columnMap.set(col.field, col);
      }
    });

    // Convert the map back to an array and update the state
    this.initialColumns = Array.from(columnMap.values());
  }

  @Mutation
  updateSelectedFilterData(newFilter: FilterType) {
    this.selectedFilter = newFilter;
  }

  @Mutation
  resetSelectedFilter() {
    this.selectedFilter = { field: "", value: [] };
  }

  @Action
  updateColumns(columns: ColumnType[]) {
    this.context.commit("updateInitialColumns", columns);
    this.context.commit("setColumns", columns);
  }

  @Action
  resetToInitialColumns() {
    this.context.commit("loging");
  }

  @Action
  updateFilterData(newFilter: string[]) {
    this.context.commit("updateSelectedFilterData", newFilter);
  }

  @Action
  resetFilterData() {
    this.context.commit("resetSelectedFilter");
  }

  // Removed the incorrect initColumns mutation as it was redundant
}

export default ReportsStore;
