import { RouteRecordRaw } from "vue-router";
import { RolePermType } from "@/store/enums/AuthEnums";

export const Campaign: Array<RouteRecordRaw> = [
  {
    path: "/campaign-management",
    name: "campaign-management",
    component: () => import("@/views/campaign/campaign-management/CampaignManagement.vue"),
    meta: {
      resources: ["CampaignManagement"],
      permissions: [RolePermType.SUPERADMIN, RolePermType.ADMIN, RolePermType.MERCHANT],
    },
    children: [
      {
        path: "/campaign-management",
        name: "campaigns-management-index",
        component: () => import("@/views/campaign/campaign-management/CampaignManagementIndex.vue"),
        meta: { resources: ["CampaignManagement"] },
      },
      {
        path: "/campaign-management/create",
        name: "campaign-management-create",
        component: () => import("@/views/campaign/campaign-management/CampaignManagementCreate.vue"),
        meta: { resources: ["CampaignManagement"] },
      },
      {
        path: "/campaign-management/edit/:id",
        name: "campaign-management-edit",
        component: () => import("@/views/campaign/campaign-management/CampaignManagementEdit.vue"),
        meta: { resources: ["CampaignManagement"] },
      },
    ],
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: () => import("@/views/campaign/campaign-publisher/CampaignPublisher.vue"),
    meta: { resources: ["Campaign"], permissions: [RolePermType.AFFILIATE] },
    children: [
      {
        path: "/campaigns",
        name: "campaigns-index",
        component: () => import("@/views/campaign/campaign-publisher/CampaignPublisherIndex.vue"),
        meta: { resources: ["Campaign"] },
      },
      {
        path: "/campaigns/detail/:id",
        name: "campaigns-show",
        component: () => import("@/views/campaign/campaign-publisher/CampaignPublisherShow.vue"),
        meta: { resources: ["Campaign"] },
      },
    ],
  },
];
export default Campaign;
